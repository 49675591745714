<template>
  <v-card class="mx-auto" max-width="800">
    <v-card-text>
      <div class="text--primary">
        <div class="text-right">{{ date.format("MMMM D") }}.</div>
        <main class="page-content pt-5" v-if="isAllowedToView">
          <slot name="page-content" class="page-content"></slot>
        </main>
        <main v-else>
          <div class="page-content">
            <h2 class="mb-5">Te kis huncut! Ne leskelődj, mert kikapsz!</h2>
            <v-img
              src="https://metro.co.uk/wp-content/uploads/2017/07/spanking.png"
              alt=""
            />
          </div>
        </main>
      </div>
    </v-card-text>
    <v-card-actions class="text-right">
        <router-link class="text-right" to="/">Vissza a naptárra</router-link>
    </v-card-actions>

  </v-card>
</template>

  
  
<script>
import Moment from "moment";

export default {
  name: "Day",
  props: ["day"],
  computed: {
    date: function () {
      return new Moment(this.day, "DD.MM.YYYY");
    },
    now: function () {
      return new Moment();
    },
    isAllowedToView: function () {
      return this.date <= this.now;
    },
    year: function () {
      return this.date.format("YYYY");
    },
  },
};
</script>

<style lang="scss">
    .v-card{
        margin-top: 50px;
    }
    .v-card__actions{
        justify-content: flex-end;
        a{
            text-decoration: none;
        }
    }
</style>
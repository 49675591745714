<template>
  <Day day="11.12.2022">
    <div slot="page-content">
      <h1 class="headline mb-5 align-center px-5">Mindig nézz a hátad mögé</h1>

      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>vagy állj tükör elé! </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-img style="margin: 0 auto" max-width="300" src="../../assets/dec11.jpg"></v-img>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </Day>
</template>
  
<script>
import Day from "@/views/days/Day.vue";

export default {
  name: "Day11",
  components: {
    Day,
  },
  mounted() {
    document.body.classList.add("northern-light");
  },
  destroyed() {
    document.body.classList.remove("northern-light");
  },
};
</script>
  

<style lang="scss">
.italic {
  font-style: italic;
}

p {
  font-size: 1.3em;
  line-height: 1.8;
}

.hover {
  filter: blur(5px);
  transition: filter 500ms ease-in-out;
  cursor: pointer;

  &:hover {
    filter: blur(0);
  }
}</style>
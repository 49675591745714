<template>
  <Day day="09.12.2023">
    <div slot="page-content">
      <h1 class="headline mb-5 align-center px-5">
        Mondd "ÁÁÁ"!
      </h1>

      <p class="px-5">
        Ma reggelre új nap virradt,
        tán, már a popsid sem sajog.
        Ülj le hát, nyisd ki szád,
        és nyeld, amit eléd rakok.
      </p>

    </div>
  </Day>
</template>
  
<script>
import Day from "@/views/days/Day.vue";

export default {
  name: "Day09",
  components: {
    Day,
  },
  mounted() {
    document.body.classList.add("");
  },
  destroyed() {
    document.body.classList.remove("");
  },
};
</script>
  

<style lang="scss">
.italic {
  font-style: italic;
}

p {
  font-size: 1.3em;
  line-height: 1.8;
}
</style>
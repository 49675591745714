<template>
  <Day day="01.12.2023">
    <div slot="page-content">
      <h1 class="headline mb-5 align-center px-5">Na rúgjuk be ezt a telet is!</h1>

      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>Vajon lehet még nedvesebb ez a december 1.? </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-img style="margin: 0 auto" max-width="300" src="../../assets/dec1.jpg"></v-img>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </Day>
</template>
  
<script>
import Day from "@/views/days/Day.vue";

export default {
  name: "Day01",
  components: {
    Day,
  },
};
</script>
  

<style lang="scss">
.page-content {}

.italic {
  font-style: italic;
}
</style>
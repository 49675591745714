<template>
  <Day day="07.12.2023">
    <div slot="page-content">
      <h1 class="headline mb-5 align-center px-5">Hoztam Neked egy pakk havat</h1>

      <!-- <p>Na mit hoztam az ivóból?</p> -->
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header> Fázol a képtől, vagy...? </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-img style="margin: 0 auto" max-width="300" src="../../assets/dec7.jpg"></v-img>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </Day>
</template>
  
<script>
import Day from "@/views/days/Day.vue";

export default {
  name: "Day07",
  components: {
    Day,
  },
  mounted() {
    document.body.classList.add("bor");
  },
  destroyed() {
    document.body.classList.remove("bor");
  },
};
</script>
  

<style lang="scss">
.italic {
  font-style: italic;
}

p {
  font-size: 1.3em;
  line-height: 1.8;
}
</style>
<template>
  <Day day="10.12.2022">
    <div slot="page-content">
      <h1 class="headline mb-5 align-center px-5">
        Pihi
      </h1>

      <p class="px-5">
        Kapsz egy kis időt, hogy kipihend a hétvégét és felkészülj a HUGE faceshot-ra. ♡
      </p>
      <img src="https://gifdb.com/images/high/facial-messy-cream-face-yes-nodding-i-agree-f4p6f4jw46llccmc.gif" alt="">
    </div>
  </Day>
</template>
  
<script>
import Day from "@/views/days/Day.vue";

export default {
  name: "Day10",
  components: {
    Day,
  },
  mounted() {
    document.body.classList.add("pihi");
  },
  destroyed() {
    document.body.classList.remove("pihi");
  },
};
</script>
  

<style lang="scss">
.italic {
  font-style: italic;
}

p {
  font-size: 1.3em;
  line-height: 1.8;
}

.hover {
  filter: blur(5px);
  transition: filter 500ms ease-in-out;
  cursor: pointer;

  &:hover {
    filter: blur(0)
  }
}
</style>
<template>
  <Day day="03.12.2023">
    <div slot="page-content">
      <h1 class="headline mb-5 align-center px-5">December három, megmasszom a párom</h1>

      <div style="text-align: center;"><img src="https://cdn.isselecta.com/2017-01/nice-sensual-massage_1500.gif"></div>
      <p class="mx-5">December 31-ig beváltható, PRÉMIUM teljes testmasszázs kupon*.</p>
      <p class="mx-5" style="font-size: .75rem;">* A PRÉMIUM szolgáltatás tartalmazza az összes választható extra
        kívánságot.</p>
    </div>
  </Day>
</template>
  
<script>
import Day from "@/views/days/Day.vue";

export default {
  name: "Day03",
  components: {
    Day,
  },
  mounted() {
    document.body.classList.add('mist')
  },
  destroyed() {
    document.body.classList.remove('mist')
  },
};
</script>
  

<style lang="scss">
.italic {
  font-style: italic;
}

p {
  font-size: 1.3em;
  line-height: 1.8;
}
</style>
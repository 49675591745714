<template>
  <v-card class="mx-auto" max-width="800">
    <v-card-text>
      <div class="text--primary">
        <!-- <h1 class="headline">{{ date.format("MMMM D") }}</h1> -->
        <main>
          <div class="page-content">
            <h2 class="mb-5">Te kis huncut! Ne leskelődj, mert kikapsz!</h2>
            <v-img
              src="https://metro.co.uk/wp-content/uploads/2017/07/spanking.png"
              alt=""
            />
          </div>
        </main>
      </div>
    </v-card-text>
    <v-card-actions>
      <router-link to="/">Vissza a naptárra</router-link>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: "NotFound",
}
</script>
<template>
  <div>
    <v-card class="mx-auto" max-width="800">
      <div class="cards-wrapper">
        <ul class="cards">
          <li class="card" v-for="i in range(1, 24)" :key="i">
            <router-link :to="'/days/day' + i" v-if="(today >= i)">
              <span class="card-content">{{ i }}</span>
            </router-link>
            <div v-else>
              <span class="card-content disabled">{{ i }}</span>
            </div>
          </li>
        </ul>
      </div>
    </v-card>
  </div>
</template>
  
<script>
  import Moment from "moment";

export default {
  name: "CalendarPage",
  data() {
    return {
      day: '3',
    }
  },
  methods: {
    range: function (min, max) {
      let arr = [];
      let j = 0;
      for (let i = min; i <= max; i++) {
        arr[j++] = i;
      }

      return arr;
    },
  },
  computed: {
    date: function () {
      return new Moment(this.now, "DD.MM.YYYY");
    },
    now: function () {
      return new Moment();
    },
    isAllowedToView: function () {
      return this.date <= this.now;
    },
    year: function () {
      return this.date.format("YYYY");
    },
    today: function () {
      return this.date.format("D");
    },
  },
};
</script>

<style lang="scss">
.cards-wrapper {
  margin-top: 50px;
  background: transprent;
  .cards {
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    .card {
      position: relative;
      width: calc(100% / 6);
      max-width: calc(100% / 6);
      // background: rgba($color: #ffffff, $alpha: 1);
      background-image: url(@/assets/gold-bg.webp);
      min-height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px dashed #ccc;
      border-bottom: 1px dashed #ccc;
      box-sizing: border-box;
      &:nth-of-type(6n) {
        border-right: none;
      }
      &:nth-of-type(n + 19) {
        border-bottom: none;
      }
      a {
        display: block;
        width: 100%;
        height: 100%;
        text-align: center;
        line-height: 6;
        text-decoration: none;
        color: rgb(75, 64, 3);
        font-weight: bold;
      }
      .card-content {
        width: 100%;
        height: 100%;
        font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
          "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
        font-size: 20px;
        &.disabled{
          display: block;
          text-align: center;
          line-height: 6;
          width: 100%;
          height: 100%;
          color: rgba(105, 91, 8, .4);
          font-weight: bold;
        }

      }
    }
  }
}
</style>
<template>
  <Day day="05.12.2023">
    <div slot="page-content">
      <h1 class="headline mb-5 align-center px-5">Bora Bora</h1>
      <p class=" px-5">Vin Dieselnek Bora Bora volt,<br />
        szád ízének forralt bor a jó.<br />
        Nedves narancs, huncut fahéj.<br />
        Öledből is folyik a lé?</p>
      <div class=" px-5">
        <img src="https://y.yarn.co/bb9da9d1-d962-4fe9-8094-bb7f679b7a07_text.gif" alt="Bora bora">
      </div>

      <p class=" px-5">Szóval, ha nem lenne egyértelmű, akkor forralt bor lesz a vacsora!</p>
      <div class=" px-5">
        <img src="https://media.tenor.com/ETGVY5iFCIkAAAAC/big-wine-only-one-glass.gif" alt="Bora bora">
      </div>
    </div>
  </Day>
</template>
  
<script>
import Day from "@/views/days/Day.vue";

export default {
  name: "Day05",
  components: {
    Day,
  },
  mounted() {
    document.body.classList.add("peach");
  },
  destroyed() {
    document.body.classList.remove("peach");
  },
};
</script>
  

<style lang="scss">
.italic {
  font-style: italic;
}

p {
  font-size: 1.3em;
  line-height: 1.8;
}
</style>
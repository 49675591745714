<template>
  <v-app>
    <v-app-bar app color="turquise" dark>
      <div class="d-flex align-center w-100">
        <h2 class="text-center">❤ Nudlidárium - 2023 🎄</h2>
      </div>

      <v-spacer></v-spacer>

      <!-- <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn> -->
    </v-app-bar>


    <v-main>
      <div class="background">
        <video autoplay loop id="video-background" :class="video.name" muted plays-inline>
          <source :src="video.src" :type="video.type" />
        </video>
      </div>
      <div class="content">
        <div class=" mt-5">
          <v-text-field v-model="password" label="Password" type="password" outlined class="mx-5"
            v-if="!passwordCorrect"></v-text-field>
          <!-- <v-btn @click="submitPassword" color="primary" class="mx-5">Submit</v-btn> -->
          <router-view v-if="passwordCorrect" />
          <div v-if="passwordCorrect">
          </div>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import Moment from "moment";
export default {
  name: "App",

  data() {
    return {
      password: "",
      sources: [
        // {
        //   src: require('./assets/octagon.mp4'),
        //   type: 'video/mp4'
        // },
        {
          src: require('./assets/golden.mp4'),
          type: 'video/mp4'
        },
        // {
        //   name: "breakfast",
        //   src: require('./assets/breakfast.mp4'),
        //   type: 'video/mp4'
        // },
        // {
        //   name: "pihi",
        //   src: require('./assets/pihi.mp4'),
        //   type: 'video/mp4'
        // }
      ]
    }
  },
  computed: {
    video: function () {
      let day = (new Moment()).format('d')
      return this.sources[day % this.sources.length];

      // random on every page load:
      // return this.sources[Math.floor(Math.random() * 3)]
    },
    passwordCorrect: function () {
      return this.password === "nudli" || this.password === "Nudli";
    }
  }
};
</script>

<style lang="scss">
#app {
  background: teal;
}

#video-background {
  /*  making the video fullscreen  */
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
}

body.breakfast #video-background.breakfast {
  z-index: 0;
}

body.pihi #video-background.pihi {
  z-index: 0;
}


// BG

.northern-light .v-main {
  background-image: url(@/assets/northern-light.jpg);
  background-position: center 10%;
  background-size: cover;
}

.mist .v-main {
  background-image: url(@/assets/mist.jpg);
  background-position: center 10%;
  background-size: cover;
}

.peach .v-main {
  background-image: url(@/assets/peach.jpg);
  background-position: center 10%;
  background-size: cover;
}

.santa .v-main {
  background-image: url(@/assets/santa.jpg);
  background-position: center 10%;
  background-size: cover;
}

// .bor .v-main {
//   background-image: url(@/assets/bor.webp);
//   background-position: center 10%;
//   background-size: cover;
// }

.party .v-main {
  background-image: url(@/assets/party.webp);
  background-position: center 10%;
  background-size: cover;
}

.pihi .v-main {
  background-image: url(@/assets/fire.jpg);
  background-position: center 10%;
  background-size: cover;
}
</style>